.App {
  overflow-x: hidden;
}

.flexrow-center {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.pointer {
  cursor: pointer;
}

.title {
  font-family: Author;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  margin: 0px;
  margin-bottom: 16px;
}

.subtitle {
  font-family: Author;
  font-size: 25px;
  font-style: normal;
  font-weight: 494;
  text-align: left;
  margin: 0px;
  margin-bottom: 32px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: var(--margin-block);
  padding-block: calc(var(--margin-block) / 2);
  background-color: black;
}

.footer-text {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.footer-title {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 16px;
}

.footer-store-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  height: 80px;
  width: calc(100% - var(--margin) * 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  position: absolute;
  top: 0px;
  margin-left: var(--margin);
}

.header-store-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
}

.store-container {
  display: none;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  width: calc(100% - var(--margin) * 2);
}

.background-video {
  display: block;
  opacity: 0.7;
  position: absolute;
  z-index: -1;
  top: 0px;
  width: auto;
  left: 50%;
  min-width: 100vw;
  transform: translate(-50%, 0);
  object-fit: cover;
}

.landing {
  min-height: 100vh;
}

.landing-container {
  padding-bottom: var(--margin-block);
  padding-top: calc(var(--margin-block) + var(--header-height));
  width: calc(100% - var(--margin) * 2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

.landing-left-container {
  width: 55%;
  margin-top: var(--margin-block);
  margin-right: 32px;
}

.landing-right-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.landing-iphone {
  height: 620px;
}

.landing-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

@media (max-width: 1028px) {
  .title {
    font-size: 45px;
  }

  .subtitle {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .title {
    text-align: center;
  }

  .subtitle {
    text-align: center;
  }

  .footer {
    flex-direction: column-reverse;
    gap: 32px;
  }

  .footer-store-container {
    flex-direction: row;
  }

  .footer-copyrigt-container {
    display: flex;
    gap: 16px;
  }

  .header {
    justify-content: center;
  }

  .header-store-container {
    display: none;
  }

  .landing {
    background: url("./assets//images/background.png");
    background-size: cover;

  }

  .background-video {
    display: none;
  }

  .landing-watchvideo {
    display: none;
  }

  .landing-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .landing-left-container {
    width: 100%;
    margin-top: var(--margin-block);
    margin-right: 0px;
  }

  .landing-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .store-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    width: calc(100% - var(--margin) * 2);
    margin: auto;
  }

  :root {
    --margin-block: 50px;
    --margin: 20px;
  }
}
